// Notifications
@mixin addNotificationIcon($icon) {
  .notification-title {
      padding-left: 1.2rem;
      // font-size: 0.9rem;

      &::before {
          content: $icon;
          position: absolute;
          left: 1.4rem;
          top: 1rem;
          font-family: "Font Awesome 5 Pro";
      }
  }
}
.react-notification-root {
  .notification-container-mobile-top {
      right: unset;
      left: unset;
      top: unset;
      .notification-item {
          border-radius: 0;
      }
  }
}

.notification-item {
  border: none !important;
  display: block;
  padding: 0.2rem 0.5rem;
  border-radius: 8px;
  z-index: 1000;
  overflow: hidden;
  transition: all 0.2s ease;
  max-width: 320px;
  min-width: 200px;
  text-align: left;

  &:hover {
      .timer {
          opacity: 1 !important;
      }
  }

  &.notification-info {
      @include addNotificationIcon("\f06a");
  }
  &.notification-danger {
      @include addNotificationIcon("\f06a");
  }

  &.notification-success {
      background: $success;
      // box-shadow: 1px 3px 14px rgba($success, 0.3);
      @include addNotificationIcon("\f058");
  }

  &.notification-info {
      background: $info;
      // box-shadow: 1px 3px 14px rgba($info, 0.3);
  }

  &.notification-error {
      background: $danger;
      // box-shadow: 1px 3px 14px rgba($danger, 0.3);
  }

  &.notification-warning {
      background: $warning;
      // box-shadow: 1px 3px 14px rgba($warning, 0.3);
  }

  .notification-content {
      .notification-title {
          font-weight: bold;
          font-size: 1rem;
      }

      .notification-message {
          line-height: 1.1;
          font-size: 1rem;
      }

      .timer {
          background: rgba(black, 0.05);
          // box-shadow: inset 1px 1px 0 rgba(black, 0.05);
          position: absolute;
          left: 0;
          right: 0;
          bottom: 0;
          opacity: 0;
          transition: opacity 0.2s ease;

          .timer-filler {
              background: rgba(white, 0.5);
          }
      }
  }
}
