[data-tooltip] {
    position: relative;

    &::before {
        content: attr(data-tooltip);
        left: 50%;
        transform: translate(-50%, -100%);
        padding: 5px 8px;
        border-radius: 5px;
        color: #fff;
        background-color: rgba(59, 69, 75, 0.85);
        width: 230px;
        line-height: 20px;
        font-size: 14px;
        text-transform: none;
    }

    &::after {
        content: "";
        border-style: solid;
        border-color: transparent;
        border-width: 7px;
        border-top-color: rgba(59, 69, 75, 0.85);
        left: 50%;
        transform: translateX(-50%);
    }

    &::before,
    &::after {
        position: absolute;
        display: block;
        top: -20px;
        cursor: default;
        opacity: 0;
        transition: 0.25s;
        pointer-events: none;
        text-align: center;
        font-weight: 400;
        line-height: 16px;
    }

    &:hover {

        &::before,
        &::after {
            opacity: 1;
        }
    }
}

@keyframes flashing {
    from {
        transform: translateX(-10em) skewX(-45deg);
    }

    to {
        transform: translateX(20em);
    }
}

.flashing-placeholder {
    position: relative;
    overflow: hidden;

    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 1.5em;
        height: 100%;
        background-color: rgba(#ffffff, 0.5);
        transform: translateX(-10em) skewX(-45deg);
        filter: blur(10px);
        animation: flashing 1.5s infinite; // TODO
    }
}

// Components
@import "components/control-panel";
@import "components/tablinks";
@import "components/filter";
@import "components/view-control";
@import "components/search";
@import "components/pagination";
@import "components/loyalty-bar";
@import "components/feedback-rating";
@import "components/date-filter";
@import "components/roller";
// =========================
@import "orderinfo/orderinfo";
@import "orderlist/order-card";
@import "orderlist/order-table";
@import "transactions/transactions";
@import "loyalty/loyalty";
@import "profile/profile";
@import "paymentmodal/payment-modal";
@import "error-modal";
@import "forgot";
@import "not-found";