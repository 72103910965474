@keyframes refresh {
    from {
        transform: rotate(-360deg);
    }

    50% {
        transform: rotate(0);
    }

    to {
        transform: rotate(360deg);
    }
}

.messenger {
    @import "messenger-placeholder";

    @include media-breakpoint-down(sm) {
        position: fixed;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        z-index: 1100;
    }

    &__wrapper {
        margin-bottom: 2.5rem;
        background: #fff;

        @include media-breakpoint-down(sm) {
            height: 100%;
        }
    }

    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0.5rem 0;
        background: #fff;

        @include media-breakpoint-down(sm) {
            padding: .5rem;
        }

        @include media-breakpoint-down(xs) {
            flex-direction: column;
        }

        .header {
            &__col {
                flex: 1 1 auto;
                display: flex;
                align-items: center;

                &:last-child {
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                }
            }

            &__label {
                margin-right: 1rem;
                color: #9bacb6;
                font-style: 14px;

                @include media-breakpoint-down(sm) {
                    display: none;
                }
            }

            &__back {
                width: 20px;
                display: inline-block;
                transform: rotate(90deg);

                svg {
                    fill: #394044;
                }

                @include media-breakpoint-up(md) {
                    display: none;
                }
            }
        }
    }

    &__body {
        display: flex;
        flex-direction: column;
        max-height: 447px;
        min-height: 162px;
        padding: 0 0 1rem;
        border-radius: 3px;
        border: 2px solid #eaf0f4;
        background-color: #f7f9fa;

        @include media-breakpoint-down(sm) {
            max-height: unset;
            min-height: unset;
            height: calc(100% - 46px); // 46px = height header
        }

        @include media-breakpoint-down(xs) {
            height: calc(100% - 84px);
        }
    }

    &__footer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 0 5px;
        margin: 0 1.5rem;
        border-top: 2px solid #eaf0f4;

        @include media-breakpoint-down(xs) {
            margin: 0 1rem;
        }
    }

    &__empty {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100px;
        color: #cbd3d9;
    }

    &__button {
        outline: none;

        &.sender {
            width: 111px;
            height: 30px;
            padding: 0;
            background-color: #f7f9fa;
            border: none;
            border-radius: 15px;
            font-weight: bold;
            color: #9bacb6;
            font-size: 16px;
            margin: 0 0.5rem;
            transition: 0.2s;

            &:first-child {
                &.active {
                    background-color: #7ac8ed;
                    color: #fff;
                }
            }

            &:last-child {
                &.active {
                    background-color: #78cd62;
                    color: #fff;
                }
            }
        }

        &.send {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 132px;
            height: 44px;
            padding: 0;
            border-radius: 23px;
            border: none;
            background-color: #eaf0f4;
            color: #3b454b;
            font-size: 18px;
            line-height: 18px;
            font-weight: 500;
            transition: .2s;

            svg {
                width: 19px;
                fill: #7ac8ed;
                margin-right: 8px;
                transition: .2s;
            }

            span {
                margin-top: 3px;
            }

            &.empty {
                color: #fff;

                svg {
                    fill: #fff;
                }
            }

            &:not(.empty):hover {
                background-color: #7f92f3;
                color: #ffffff;

                svg {
                    fill: #fff;
                }
            }

            @include media-breakpoint-down(sm) {
                width: 45px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                background: none;

                &:not(.empty):hover {
                    background: none;

                    svg {
                        fill: #7ac8ed;
                    }
                }

                span {
                    display: none;
                }

                svg {
                    margin-right: 0;
                }
            }
        }
    }

    &__helpers {
        display: flex;
        align-items: center;
        background: none;
        border: none;
        padding: 2px 5px;
        margin: 0 3px;
        color: #9bacb6;
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;

        svg {
            display: inline-block;
            width: 16px;
            fill: #9bacb6;
            margin-right: 4px;
            transition: .7s;
            transform: rotate(0);
        }

        @include media-breakpoint-down(xs) {
            span {
                display: none;
            }

            margin-top: 10px;
        }

        &.fetching {
            svg {
                animation: refresh 2s infinite;
            }
        }
    }

    &__view {
        // display: flex;
        position: relative;
        flex-direction: column;
        justify-content: flex-end;
        flex: 85%;
        padding: 10px 1.5rem;
        margin: 10px 10px 10px 0;
        max-height: 447px;
        min-height: 100px;
        overflow-y: auto;
        $chat-color: #78cd62;

        @include media-breakpoint-down(sm) {
            max-height: unset;
        }

        @include media-breakpoint-down(xs) {
            padding: 10px 1rem;
        }

        &.writer-chat {
            $chat-color: #78cd62;
            @include customize-scrollbars(8px, $chat-color, #eaf0f4, visible, $border-radius: 2px);
        }

        &.support-chat {
            $chat-color: #7ac8ed;
            @include customize-scrollbars(8px, $chat-color, #eaf0f4, visible, $border-radius: 2px);
        }

        .message {
            &__wrapper {
                display: flex;
                flex-direction: column;
                padding: 2px 0;
                align-items: flex-start;

                &.own {
                    align-items: flex-end;
                }

                &.writer {}

                &.support {}
            }

            &__container {
                min-width: 200px;
                max-width: 450px;

                @include media-breakpoint-down(sm) {
                    min-width: 120px;
                    max-width: 260px;
                }
            }

            &__content {
                width: 100%;
                padding: 0.4rem 0.8rem;
                border-radius: 3px;
                position: relative;
                white-space: pre-line;

                &.own {
                    &::after {
                        content: "";
                        position: absolute;
                        width: 0;
                        bottom: 0;
                        right: -8px;
                        height: 0;
                        border-bottom: 6px solid #eaf0f4;
                        border-right: 10px solid transparent;
                    }

                    background-color: #eaf0f4;
                    color: #3b454b;
                }

                &.writer {
                    background-color: #78cd62;
                    color: #fff;

                    &::after {
                        content: "";
                        position: absolute;
                        width: 0;
                        height: 0;
                        bottom: 0;
                        left: -8px;
                        border-bottom: 6px solid #78cd62;
                        border-left: 10px solid transparent;
                    }
                }

                &.support {
                    background-color: #7ac8ed;
                    color: #fff;

                    &::after {
                        content: "";
                        position: absolute;
                        width: 0;
                        bottom: 0;
                        left: -8px;
                        height: 0;
                        border-bottom: 6px solid #7ac8ed;
                        border-left: 10px solid transparent;
                    }
                }
            }

            &__text {
                display: flex;
                overflow: hidden;

                @include media-breakpoint-down(xs) {
                    font-size: 15px;
                }
            }

            &__file {
                display: flex;
                align-items: center;

                @include media-breakpoint-down(xs) {
                    font-size: 14px;
                }

                a {
                    color: #ffff;
                    display: flex;
                    align-items: center;
                }

                svg {
                    width: 20px;
                    height: 20px;
                    min-width: 20px;
                    margin-right: 7px;
                }
            }

            &__footer {
                display: flex;
                justify-content: flex-end;
                align-items: center;
                width: 100%;
                color: #9bacb6;
                font-size: 11px;
            }

            &__sender {
                flex: 10%;
            }

            &__time {
                flex: 90%;
                text-align: right;
            }

            &__notify {
                text-align: center;
                color: #fff;
                margin: 10px auto;
                max-width: 320px;
                border-radius: 3px;
                cursor: pointer;
                box-shadow: 0px 3px 9px #c5c5c5;

                svg {
                    width: 15px;
                    fill: #fff;
                }

                span {
                    font-weight: bold;
                }

                &.writer {
                    background: #78cd62;
                }

                &.support {
                    background: #7ac8ed;
                }
            }
        }
    }

    &__input-wrapper {
        position: relative;
        display: flex;
        flex-direction: column;
        flex-grow: 1;
    }

    &__char-counter {
        position: absolute;
        left: 3px;
        bottom: -13px;
        color: #9bacb6;
        font-size: 10px;
    }

    &__input {
        background: transparent;
        border: none;
        color: #3b454b;
        font-size: 16px;

        &:disabled {
            border: none;

            &::placeholder {
                color: #cbd3d9;
            }
        }
    }

    &__sender {
        display: flex;
        flex-direction: column;
        position: relative;

        &::after {
            content: "Ctrl + Enter";
            position: absolute;
            bottom: -19px;
            left: 50%;
            transform: translateX(-50%);
            width: 100%;
            text-align: center;
            font-size: 12px;
            color: #cbd3d9;
            font-weight: 400;
            text-transform: uppercase;

            @include media-breakpoint-down(sm) {
                display: none;
            }
        }
    }
}