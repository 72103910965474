.messenger-placeholder {
    &__wrapper {
        height: 280px;
        width: 100%;

        @include media-breakpoint-down(sm) {
            height: 100%;
        }
    }

    &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0.5rem 0;
        background: #fff;

        @include media-breakpoint-down(xs) {
            flex-direction: column;
            justify-content: center;
        }

        .header {
            &__col {
                display: flex;
                align-items: center;
            }

            &__label {
                width: 120px;
                height: 14px;
                border-radius: 3px;
                margin-right: 1rem;
                background: #f7f9fa;

                @include media-breakpoint-down(sm) {
                    display: none;
                }
            }

            &__togglers {
                width: 111px;
                height: 30px;
                margin: 0 0.5rem;
                border-radius: 15px;
                background: #f7f9fa;

                @include media-breakpoint-down(xs) {
                    margin-bottom: 0.5rem;
                }
            }

            &__helpers {
                width: 80px;
                height: 20px;
                border-radius: 3px;
                margin-right: 0.5rem;
                background: #f7f9fa;

                &:first-child {
                    width: 40px;
                }

                @include media-breakpoint-down(xs) {
                    width: 40px;
                }
            }
        }
    }

    &__body {
        max-height: 280px;
        min-height: 230px;
        padding: 0;
        border-radius: 3px;
        border: 2px solid #eaf0f4;
        background: #f7f9fa;

        @include media-breakpoint-down(sm) {
            max-height: unset;
            min-height: unset;
            height: calc(100% - 46px); // 46px = height header
        }

        @include media-breakpoint-down(xs) {
            height: calc(100% - 70px);
        }
    }

    &__view {
        display: flex;
        position: relative;
        flex-direction: column;
        justify-content: flex-end;
        flex: 85%;
        padding: 10px 1.5rem;
        margin: 10px 10px 10px 0;
        height: 150px;
        overflow-y: auto;

        @include media-breakpoint-down(sm) {
            height: calc(100% - 100px);
        }

        .msg {
            display: flex;
            flex-direction: column;
            padding: 2px 0;
            height: 30px;
            margin: 2px 0;
            border-radius: 3px;
            align-items: flex-start;
            background: #eaf0f4;
            box-shadow: none;

            &.left {
                align-self: flex-start;
            }

            &.right {
                align-self: flex-end;
            }

            &:nth-child(odd) {
                width: 115px;
            }

            &:nth-child(even) {
                width: 70px;
            }
        }
    }

    &__footer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 0 5px;
        margin: 0 1.5rem;
        border-top: 2px solid #eaf0f4;

        @include media-breakpoint-down(xs) {
            margin: 0 1rem;
        }
    }

    &__input {
        display: flex;

        span {
            width: 150px;
            height: 14px;
            border-radius: 3px;
            margin-right: 1rem;
            background: #eaf0f4;
            margin-right: 0.4rem;

            @include media-breakpoint-down(xs) {
                &:last-child {
                    display: none;
                }
            }
        }
    }

    &__button {
        width: 131px;
        height: 40px;
        margin: 0 0.5rem;
        border-radius: 15px;
        background: #eaf0f4;
    }
}