.truncate-wrapper {
    display: flex;
    align-items: center;
    margin-right: 0 !important;
}

.truncate {
    max-width: 360px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    @include media-breakpoint-down(sm) {
        max-width: 215px;
    }
}

.copy-btn {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    border: none;
    box-shadow: none;
    offset: none;
    width: 35px;
    height: 35px;
    background-color: #7f92f3;
    border-radius: 50%;
    transition: 0.25s;

    &:hover {
        background-color: lighten(#7f92f3, 7%);
        box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
    }

    svg {
        width: 18px;
        height: 20px;
        fill: #fff;
    }
}

.placeholder {
    &__ready {
        max-width: 320px;
        height: 37px;
        border-radius: 3px;
        background: #eaf0f4;
        margin-bottom: 25px;

        @include media-breakpoint-down(xs) {
            height: 33px;
        }
    }

    &__link {
        display: inline-block;
        width: 100%;
        width: 360px;
        height: 28px;
        border-radius: 3px;
        background: #e1eaf1;
        margin-right: 10px;

        @include media-breakpoint-down(sm) {
            width: 215px;
        }
    }
}

@import 'discount/level';
@import 'discount/code';
@import 'discount/ribbon';
@import 'referral/link';
@import 'referral/discount-card';