.revision-create {
    position: relative;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 5px 15px rgba(19, 22, 23, 0.15);
    width: 100vw;
    max-width: 888px;
    overflow: hidden;
    padding: 36px 90px;

    @include media-breakpoint-down(lg) {
        padding: 36px 40px;
        max-width: 720px;
    }

    @include media-breakpoint-down(sm) {
        padding: 35px 20px;
    }

    .modal {
        &__close-btn {
            position: absolute;
            top: 5px;
            right: 5px;
            background-color: transparent;
            border: none;
            outline: none;
            box-shadow: none;
        }

        &__title {
            font-size: 31px;
            font-weight: 700;
            color: #3b454b;

            @include media-breakpoint-down(xs) {
                font-size: 25px;
            }
        }

        &__text {
            @include media-breakpoint-down(xs) {
                line-height: 22px;
            }
        }

        &__categories {
            display: flex;
            flex-wrap: wrap;
            margin: 30px 0 15px;

            li {
                flex-basis: 50%;
                margin-bottom: 5px;

                @include media-breakpoint-down(xs) {
                    flex-basis: 100%;
                }
            }
        }

        &__description {
            border-radius: 8px;
            border: 4px solid #f7f9fa;
            background-color: #ffffff;
            padding: 13px 25px 10px;
            font-weight: 600;
            max-height: 150px;
            transition: $transition;
            @include customize-scrollbars(10px, #cdcdcd, #f0f0f0, visible);

            &::-webkit-scrollbar-track {
                border-radius: 0;
                box-shadow: none;
            }

            &::-webkit-scrollbar-thumb {
                border-radius: 0;
            }

            @include media-breakpoint-down(xs) {
                font-size: 14px;
                padding-top: 8px;
            }

            &::placeholder {
                color: #9bacb6;
                font-weight: 600;
            }

            &.done {
                color: $main-color;
            }

            &.done,
            &:focus,
            &:hover {
                border-color: $main-color;
            }

            &:focus {
                box-shadow: 0 10px 35px $main-color;
            }
        }

        &__complexity {
            display: flex;

            @media screen and (max-width: 500px) {
                flex-wrap: wrap;
            }

            &-left,
            &-right {
                flex-basis: 50%;

                @media screen and (max-width: 500px) {
                    flex-basis: 100%;
                }
            }

            &-right {
                padding-left: 50px;

                @media screen and (max-width: 500px) {
                    padding-left: 0;
                    margin-top: 15px;
                }
            }
        }

        &__footer {
            border-top: 2px solid #eaf0f4;
            display: flex;
            padding-top: 20px;
            margin-top: 30px;

            button {
                border: none;
                box-shadow: none;
                background-color: transparent;
                font-size: 14px;
                font-weight: 700;
                color: #9bacb6;
                padding: 0;
            }
        }

        &__prev-btn {
            transition: $transition;

            &:hover {
                color: $main-color;
            }
        }

        &__next-btn {
            margin-left: auto;

            &.active {
                color: $main-color;
            }

            &:disabled {
                pointer-events: none;
            }
        }
    }

    .checkbox {
        &__label {
            display: flex;
            align-items: center;
            padding-left: 0;
            color: #3b454b;
            line-height: initial !important;
            height: initial !important;

            &::before {
                display: none;
            }

            &:hover {
                .checkbox__button {
                    border-color: $main-color;
                }
            }

            span {
                font-size: 18px;
                font-weight: 600;
            }
        }

        &__button {
            display: flex;
            justify-content: center;
            align-items: center;
            order: -1;
            min-width: 32px;
            height: 32px;
            border: 4px solid #e1e6e8;
            background-color: #ffffff;
            border-radius: 8px;
            margin-right: 15px;
            transition: $transition;

            svg {
                fill: #fff;
                transition: $transition;
            }
        }

        &__link {
            color: #7f92f3;
            font-weight: 600;
            transition: $transition;

            &:hover {
                color: lighten(#7f92f3, 5%);
            }
        }
    }

    .checkbox__input:checked+.checkbox__label {
        .checkbox__button {
            border-color: $main-color;

            svg {
                fill: $main-color;
            }
        }
    }

    .file {
        &__wrapper {
            display: flex;
            margin-top: 20px;

            @include media-breakpoint-down(sm) {
                flex-wrap: wrap;
            }
        }

        &__left {
            @include media-breakpoint-down(sm) {
                width: 100%;
            }
        }

        &__right {
            display: flex;
            align-items: center;
            flex-grow: 1;

            @include media-breakpoint-down(sm) {
                margin-top: 15px;
            }

            @include media-breakpoint-down(xs) {
                margin-top: 15px;
            }
        }

        &__input {
            display: none;
        }

        &__container {
            display: flex;
            flex-wrap: wrap;
            margin-bottom: 0;

            li:not(:last-child) {
                margin-right: 15px;
            }

            @include media-breakpoint-down(sm) {
                margin-top: 15px;
            }
        }

        &__button {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 165px;
            height: 60px;
            border-radius: 8px;
            border: 4px solid $grey-color;
            background-color: #ffffff;
            transition: $transition;
            font-weight: 500;
            color: #9bacb6;
            margin-right: 20px;

            &.not-empty {
                border: 4px solid $main-color;
                color: $main-color;

                svg {
                    fill: $main-color;
                }
            }

            &:hover {
                box-shadow: 0 10px 35px $main-color;
                background-color: $main-color;
                color: #fff;
                border: 4px solid $main-color;

                svg {
                    fill: #fff;
                }
            }

            &:focus {
                box-shadow: 0 10px 35px $main-color;
                border: 4px solid $main-color;
            }

            &:active {
                transition: 0.1s;
                box-shadow: none;
            }

            svg {
                fill: #9bacb6;
                width: 14px;
                height: 22px;
                transition: $transition;
                margin-right: 8px;
            }

            @include media-breakpoint-down(sm) {
                width: 100%;
            }
        }

        &__name {
            display: inline-flex;
            align-items: center;
            font-size: 14px;
            font-weight: 700;
            cursor: pointer;

            &:hover {
                .file__delete {

                    &::before,
                    &::after {
                        background-color: $main-color;
                    }
                }
            }
        }

        &__delete {
            display: inline-block;
            position: relative;
            width: 12px;
            height: 12px;
            margin: 0 5px -1px 0;

            &::before,
            &::after {
                content: "";
                position: absolute;
                display: block;
                width: 15px;
                height: 2px;
                top: 50%;
                left: 50%;
                background-color: #9bacb6;
                transition: $transition;
            }

            &::before {
                transform: translate(-50%, -50%) rotate(45deg);
            }

            &::after {
                transform: translate(-50%, -50%) rotate(-45deg);
            }
        }

        &__title {
            display: inline-block;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            max-width: 135px;

            @include media-breakpoint-down(sm) {
                max-width: 100px;
            }
        }
    }

    .levels {
        &__item {
            label {
                position: relative;
                padding-left: 45px;
                margin-bottom: 20px;
                font-size: 18px;
                color: #3b454b;
                font-weight: 700;
                cursor: pointer;
                height: initial !important;

                @include media-breakpoint-down(sm) {
                    font-size: 16px;
                }

                &:hover {
                    &::before {
                        border: 4px solid $main-color;
                    }
                }

                &::before {
                    content: "";
                    position: absolute;
                    display: block;
                    width: 32px;
                    height: 32px;
                    top: 50%;
                    left: 0;
                    transform: translateY(-50%);
                    margin: 0;
                    border-radius: 50%;
                    box-shadow: inset 0 0 0 4px #ffffff;
                    border: 4px solid #e1e6e8;
                    background-color: #ffffff;
                    transition: $transition;

                    @include media-breakpoint-down(sm) {
                        width: 30px;
                        height: 30px;
                    }
                }

                &::after {
                    display: none !important;
                }
            }
        }
    }

    .levels__input:checked+.levels__label {
        &::before {
            border-color: $main-color;
            background-color: $main-color;
        }
    }

    .complexity {

        &__time,
        &__eta {
            color: #3b454b;
            font-weight: 600;

            span {
                display: block;
            }

            .label {
                display: inline-flex;
                align-items: center;

                svg {
                    fill: #9bacb6;
                    width: 16px;
                    height: 16px;
                    margin-right: 10px;
                }
            }

            .content {
                font-size: 26px;

                @include media-breakpoint-down(sm) {
                    font-size: 22px;
                }
            }
        }

        &__time {
            margin-bottom: 12px;
        }
    }

    .modalnav {
        $grey-color: #e1e6e8;

        &__list {
            position: relative;
            display: flex;
            justify-content: center;
            margin-bottom: 0;
            z-index: 1;

            @media screen and (max-width: 385px) {
                margin-bottom: 10px;
            }
        }

        &__item {
            text-align: center;
            width: 120px;

            span {
                display: block;
            }

            &:not(:first-child) {
                .modalnav__link {
                    &::after {
                        content: "";
                        position: absolute;
                        display: block;
                        width: 115px;
                        height: 2px;
                        background-color: $grey-color;
                        top: 50%;
                        right: 10px;
                        transform: translateY(-50%);
                        z-index: -1;

                        @media screen and (max-width: 350px) {
                            width: 100px;
                        }
                    }
                }
            }
        }

        &__link {
            position: relative;
            display: block;
            border: none;
            background-color: transparent;
            box-shadow: none;
            width: 24px;
            height: 24px;
            color: #fff;
            margin: 0 auto 10px;

            &::before {
                content: '';
                position: absolute;
                display: block;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 100%;
                height: 100%;
                width: 16px;
                height: 16px;
                background-color: $grey-color;
                border-radius: 50%;
                transition: $transition;
            }

            &:hover {
                &::before {
                    background-color: $main-color;
                }
            }

            &.visited {
                &::before {
                    background-color: $main-color;
                }
            }

            &.active {
                font-size: 16px;

                &::before {
                    content: attr(data-link-number);
                    background-color: $main-color;
                    width: 24px;
                    height: 24px;
                }
            }
        }

        &__info-text {
            font-size: 13px;
            color: #3b454b;
            font-weight: 700;
            line-height: 16px;
            min-height: 35px;
        }
    }
}