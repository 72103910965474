.loyalty-bar {
    &__wrapper {
        margin-bottom: 24px;
    }

    &__info {
        color: #ee5ca1;
        font-size: 14px;
        font-weight: 700;
        margin-bottom: 8px;
    }

    &__discount,
    &__referral {
        text-transform: uppercase;
        color: #ee5ca1;
        transition: $transition;

        &:hover {
            color: lighten(#ee5ca1, 7%);
        }
    }

    &__referral {
        text-transform: none;
    }

    &__bar {
        width: 526px;
        height: 8px;
        border-radius: 4px;
        background-color: #eaf0f4;
        overflow: hidden;
    }

    &__indicator {
        height: 8px;
        background-color: #78cd62;
    }

    &__placeholder {
        height: 20px;
        width: 190px;
        background: #eaf0f4;
        border-radius: 3px;
        margin-bottom: 24px;
    }
}