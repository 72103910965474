.transactions {
    &__dataPaid {
        padding-top: 10px;
        padding-bottom: 15px;
        font-size: 16px;
    }

    &__workType {
        font-size: 16px;
        font-weight: bold;
    }
}