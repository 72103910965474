.details__notification {
    position: relative;
    background-color: #78cd62;
    border-radius: 3px;
    color: #fff;
    padding: 15px 40px 15px 25px;
    margin-bottom: 25px;

    @include media-breakpoint-down(xs) {
        padding: 15px;
    }

    .notification {
        &__button {
            position: absolute;
            display: flex;
            justify-content: center;
            align-items: center;
            top: 0;
            right: 0;
            width: 36px;
            height: 36px;
            padding: 0;
            margin: 0;
            border: none;
            box-shadow: none;
            background: transparent;

            svg {
                fill: #fff;
            }
        }

        &__title {
            margin-bottom: 5px;
            font-size: 19px;
            font-weight: 700;
        }

        &__text {
            margin-bottom: 10px;
        }

        &__list {
            list-style-type: disc;
            padding-left: 16px;
        }

        &__list-item {
            margin-bottom: 5px;
        }

        &__revision {
            font-weight: 600;
            border-bottom: 2px dashed rgba(255, 255, 255, 0.5);
            cursor: pointer;
            color: #fff;
        }
    }
}