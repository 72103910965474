.forgot-modal {
    position: relative;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 5px 15px rgba(19, 22, 23, 0.15);
    width: 100vw;
    max-width: 888px;
    overflow: hidden;
    padding: 0 60px 35px;

    @include media-breakpoint-down(md) {
        max-width: 700px;
    }

    @include media-breakpoint-down(sm) {
        padding: 0 20px 20px;
    }

    @include media-breakpoint-down(xs) {
        padding: 20px;
    }

    .forgot-modal {
        &__body {}

        &__exit {
            position: absolute;
            top: 5px;
            right: 5px;
            border: none;
            box-shadow: none;
            background-color: transparent;

            @include media-breakpoint-up(sm) {
                display: none;
            }
        }

        &__header {
            text-align: center;
            font-size: 30px;
            font-weight: bold;
            padding: 25px 0 0;
            color: #3b454b;

            @include media-breakpoint-down(xs) {
                font-size: 24px;
            }
        }

        &__footer {
            display: flex;
            justify-content: space-between;
            margin: 1rem 0;
            padding-top: 1rem;
            border-top: 2px solid #eaf0f4;

            .forgot-modal-col {
                display: flex;

                @include media-breakpoint-down(xs) {
                    flex-direction: column;
                    align-items: center;
                }

                &:first-child {
                    flex: 1 1 auto;
                }

                &:last-child {
                    @include media-breakpoint-down(xs) {
                        display: none;
                    }
                }
            }
        }

        &__submit {
            background: none;
            border: none;
            outline: none;

            @include media-breakpoint-down(xs) {
                min-width: 250px;
            }

            &.reset-pass {
                display: flex;
                position: relative;
                justify-content: center;
                align-items: center;
                border-radius: 30px;
                padding: 5px 25px;
                border: 4px solid #59d053;
                outline: none;
                background-color: #59d053;
                color: #ffffff;
                font-size: 18px;
                font-weight: 500;
                margin-right: 1rem;
                transition: $transition;

                @include media-breakpoint-down(xs) {
                    margin: 0 0 15px
                }

                &:hover {
                    box-shadow: 0 3px 8px 0 #59d053;
                }
            }

            &.magic-link {
                display: flex;
                position: relative;
                justify-content: center;
                align-items: center;
                border-radius: 30px;
                padding: 5px 25px;
                border: 4px solid #7f92f3;
                outline: none;
                background-color: #7f92f3;
                color: #ffffff;
                font-size: 18px;
                font-weight: 500;
                transition: $transition;

                &:hover {
                    box-shadow: 0 3px 8px 0 #7f92f3;
                }
            }
        }

        &__close {
            border: none;
            outline: none;
            background-color: transparent;
            box-shadow: none;
            color: #d76c6d;
            font-weight: 700;
        }

        &__label {
            color: #3b454b;
            font-size: 18px;
            width: 100%;
        }

        &__text {
            font-size: 16px;
            margin: 3rem 0 1rem;
            color: #3b454b;

            @include media-breakpoint-down(sm) {
                margin: 1rem 0;
            }
        }
    }

    #reset-password {
        display: flex;
        align-items: center;
        border-radius: 8px;
        border: 4px solid var(--grey-color, #f7f9fa);
        height: 60px;
        min-height: 60px;
        padding: 0 25px;
        font-size: 22px;
        margin-top: 0.5rem;
        font-weight: 500;
        color: #3b454b;
        background-size: 21px 16px;
        background-position: 25px 16px;
        transition: 0.25s;

        @include media-breakpoint-down(xs) {
            font-size: 15px;
        }

        &::placeholder {
            color: #9bacb6;
        }

        &:hover {
            box-shadow: 0 10px 35px var(--main-color, #7ac8ed);
            background-size: 21px 16px;
            background-position: 25px 16px;
            border: 4px solid var(--main-color, #7ac8ed) !important;
        }

        &:focus {
            background-size: 21px 16px;
            background-position: 25px 16px;
            border: 4px solid var(--main-color, #7ac8ed) !important;
        }

        &.done {
            color: var(--main-color, #7ac8ed);
            border: 4px solid var(--main-color, #7ac8ed) !important;
            background-size: 21px 16px;
            background-position: 25px 16px;
            padding-left: 25px;
        }
    }
}