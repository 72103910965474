.date-filter {
    position: relative;
    display: flex;
    align-items: center;
    z-index: 1;

    @include media-breakpoint-down(sm) {
        margin-top: 13px;
    }

    @media screen and (max-width: 450px) {
        flex-direction: column;
        align-items: start;
    }

    &__label {
        margin-right: 8px;
        color: #9bacb6;

        @include media-breakpoint-down(xs) {
            font-size: 15px;
        }
    }

    &__input-block {
        position: relative;
        display: flex;
        justify-content: space-between;
        max-width: 240px;
        background-color: #fff;
    }

    &__input {
        position: relative;
        flex-basis: calc(50% - 4px);
        font-size: 14px;

        &:hover {
            input {
                color: $main-color;

                &::placeholder {
                    color: $main-color;
                }
            }

            svg {
                fill: $main-color;
            }
        }

        &.has-value {
            input {
                color: $main-color;
            }

            svg {
                fill: $main-color;
            }
        }

        input {
            border: none;
            box-shadow: none;
            padding: 0;
            height: 26px;
            transition: $transition;
            cursor: pointer;

            &::placeholder {
                color: #9bacb6;
                font-size: 14px;
                transition: $transition;
            }
        }

        svg {
            position: absolute;
            fill: #9bacb6;
            width: 14px;
            height: 14px;
            right: 0;
            top: 46%;
            transform: translateY(-50%);
            transition: $transition;
        }

        border-bottom: 1px solid #eaf0f4;
    }

    &__calendar-holder {
        position: absolute;
        top: 30px;
        left: 0;
        box-shadow: 0 5px 15px rgba(19, 22, 23, 0.35);
    }

    .date-picker-calendar {
        .Cal__Header__root {
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
        }

        .Cal__MonthList__root {
            overflow-x: hidden !important;
            @include customize-scrollbars(4px, $main-color, #fbfbfb, visible);
        }

        .Cal__Day__root {
            font-weight: 400;

            &.Cal__Day__selected {
                .Cal__Day__selection {
                    width: 50px;
                    height: 50px;
                    background-color: $main-color;
                }
            }
        }
    }
}