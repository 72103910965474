#page.page-wrapper {
    overflow: visible;
}

.is-messenger-opened {
    @media screen and (max-width: 767px) {
        overflow: hidden;
        position: relative;
    }
}

.section-customer-dashboard {
    @import "variables";
    @import "global", "animations", "intl-tel-input", "infinite-calendar";
    // Components
    @import "App";
    @import "orderform";
    @import "./dashboard/index";
    @import "Notification";
    @import "login";
}

.section {
    background: transparent !important;
}
