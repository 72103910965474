.file {
    &__group {
        margin: 25px 0 20px;
    }

    &__wrapper {
        display: flex;
        @include media-breakpoint-down(sm) {
            flex-wrap: wrap;
        }
    }

    &__left {
        @include media-breakpoint-down(sm) {
            width: 100%;
        }
    }

    &__right {
        display: flex;
        align-items: center;
        flex-grow: 1;

        @include media-breakpoint-down(xs) {
            margin-top: 15px;
        }
    }

    &__input {
        display: none;
    }

    &__container {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 0;

        li:not(:last-child) {
            margin-right: 15px;
        }
        @include media-breakpoint-down(sm) {
            margin-top: 15px;
        }
    }

    &__button {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 165px;
        height: 60px;
        border-radius: 8px;
        border: 4px solid $grey-color;
        background-color: #ffffff;
        transition: $transition;
        font-weight: 500;
        color: #9bacb6;
        margin-right: 20px;

        &.not-empty {
            border: 4px solid $main-color;
            color: $main-color;

            svg {
                fill: $main-color;
            }
        }

        &:hover {
            box-shadow: 0 10px 35px $main-color;
            background-color: $main-color;
            color: #fff;
            border: 4px solid $main-color;

            svg {
                fill: #fff;
            }
        }

        &:focus {
            box-shadow: 0 10px 35px $main-color;
            border: 4px solid $main-color;
        }

        &:active {
            transition: 0.1s;
            box-shadow: none;
        }

        svg {
            fill: #9bacb6;
            width: 14px;
            height: 22px;
            transition: $transition;
            margin-right: 8px;
        }

        @include media-breakpoint-down(sm) {
            width: 100%;
        }
    }

    &__name {
        display: inline-flex;
        align-items: center;
        font-size: 14px;
        font-weight: 700;
        cursor: pointer;

        &:hover {
            .file__delete {

                &::before,
                &::after {
                    background-color: $main-color;
                }
            }
        }
    }

    &__delete {
        display: inline-block;
        position: relative;
        width: 12px;
        height: 12px;
        margin: 0 5px 4px 0;

        &::before,
        &::after {
            content: "";
            position: absolute;
            display: block;
            width: 15px;
            height: 2px;
            top: 50%;
            left: 50%;
            background-color: #9bacb6;
            transition: $transition;
        }

        &::before {
            transform: translate(-50%, -50%) rotate(45deg);
        }

        &::after {
            transform: translate(-50%, -50%) rotate(-45deg);
        }
    }

    &__title {
        display: inline-block;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        max-width: 135px;

        @include media-breakpoint-down(sm) {
            max-width: 100px;
        }
    }
}