.summary {
    border: 4px solid $grey-color;
    border-radius: 8px;
    width: 255px;
    padding: 13px 20px 20px;
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 25px;
    background: #fff;

    @include media-breakpoint-down(md) {
        width: 100%;
    }

    &__wrapper {
        @include media-breakpoint-up(lg) {
            position: sticky;
            top: 82px;
        }

        @include media-breakpoint-down(md) {
            margin-top: 30px;
        }
    }

    hr {
        display: block;
        margin: 5px 0;
        border: 0;
        border-top: 2px solid rgba(0, 0, 0, 0.1);
    }

    &__title {
        font-size: 24px;
        font-weight: 600;
    }

    &__work-title {
        display: inline-block;
        margin: 8px 0 10px;
    }

    &__row {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;

        &.total {
            font-size: 18px;
            font-weight: 600;
        }

        &.visible {
            border-bottom: 2px solid $main-color;
        }

        &.apply {
            input {
                color: #9bacb6;
            }
        }

        &.info {
            justify-content: flex-end;
            margin-bottom: 0;
            min-height: 19px;
        }

        &.plagiarism,
        &.extraquality,
        &.onepagesummary {
            margin-bottom: 0;

            span {
                margin-bottom: 10px;
            }
        }
    }

    &__discount-info {
        span:first-child {
            position: relative;
            color: #9bacb6;
            font-size: 13px;

            &::after {
                content: "";
                position: absolute;
                display: block;
                width: 100%;
                height: 1px;
                top: 40%;
                left: 0;
                background-color: #9bacb6;
            }
        }

        span:last-child {
            color: #78cd62;
            font-size: 13px;
        }
    }

    &__discount-check {
        position: relative;
        border: none;
        outline: none;
        box-shadow: none;
        background-color: transparent;
        padding: 0;

        &::after {
            content: "";
            position: absolute;
            display: block;
            height: 1px;
            width: 100%;
            left: 0;
            bottom: 0;
            background-color: #ced0d2;
        }
    }

    &__discount-input {
        min-width: 50px;
        border: none;
        background-color: transparent;
        outline: none;
        box-shadow: none;
        padding: 0;

        &::placeholder {
            color: #e1e6e8;
            font-size: 16px;
        }
    }

    &__discount-btn {
        border: none;
        background-color: transparent;
        outline: none;
        box-shadow: none;
        text-transform: uppercase;
        color: #9bacb6;
        font-weight: 500;
        padding: 0;
        transition: $transition;

        &:hover {
            color: $main-color;
        }
    }

    &__discount-activated {
        color: $main-color;

        svg {
            fill: $main-color;
            margin-right: 5px;
        }
    }

    .total-price {
        transition: transform 0.1s;
        transform-origin: center;
    }

    .flush {
        color: $main-color;
        transform: scale(1.1);
    }
}