@import "success";
@import "preferred";
@import "table";

.details {
    &__status-block {
        border-radius: 3px;
        background-color: #eaf0f4;
        padding: 15px 25px;
        margin-bottom: 20px;

        &.completed {
            padding: 0;
            background-color: transparent;
        }

        .oferred-title {
            font-size: 22px;
            font-weight: 700;
            margin-bottom: 0;
        }

        .oferred-writers {
            display: block;
            font-size: 16px;
            font-weight: 600;
            margin-bottom: 10px;
        }

        .status-pay {
            cursor: pointer;
        }
    }

    &__pending-wrapper {
        display: flex;
        align-items: center;

        p {
            margin: 0 0 0 10px;
        }
    }

    &__modify {
        border-radius: 3px;
        background-color: #eaf0f4;
        padding: 20px 24px 1px;
        margin-bottom: 20px;

        &-title {
            font-size: 22px;
            font-weight: 700;
        }

        &-table {
            margin-bottom: 10px;
        }

        .modify {
            &__label {
                display: inline-block;
                font-size: 14px;
                color: #9bacb6;
                font-weight: 700;
                margin-right: 10px;
            }

            &__before {}

            &__after {
                color: #5ed257;
            }
        }
    }

    &__btn-block {
        display: flex;
        justify-content: space-between;
        max-width: 540px;
        margin-bottom: 10px;

        @media screen and (max-width: 400px) {
            flex-wrap: wrap;
        }
    }
}

.unpaid,
.paid,
.in-progress,
.modified,
.offered {
    .details__pay-btn.details {
        background-color: #59d053;

        @include media-breakpoint-down(sm) {
            max-width: 240px;
        }

        @media screen and (max-width: 400px) {
            max-width: 100%;
        }

        &:hover {
            box-shadow: 0 1px 5px 0 #59d053
        }
    }

    .details__edit-btn.details {
        background-color: #fff;
        color: #3b454b;
        border: 2px solid #9bacb6;

        @include media-breakpoint-down(sm) {
            max-width: 240px;
            margin-left: 25px;
        }

        @media screen and (max-width: 400px) {
            max-width: 100%;
            margin-left: 0;
        }

        &:hover {
            box-shadow: 0 4px 14px -4px rgba(21, 88, 168, .2), 0 2px 25px -5px rgba(21, 88, 168, .1), 0 1px 1px rgba(21, 88, 168, .05);
        }
    }
}