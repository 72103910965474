&-support {
    &__wrapper {
        display: flex;
        padding: 15px 25px;
        margin-bottom: 25px;
        background-color: #eaf0f4;
        border-radius: 3px;
        
        @include media-breakpoint-down(sm) {
            padding: 10px 10px;
        }
    }

    &__icon {
        display: flex;
        margin-right: 15px;
        padding-top: 5px;

        svg {
            width: 40px;
            height: 38px;
        }

        @include media-breakpoint-down(sm) {
            display: none;
        }
    }

    &__info {
        list-style-type: disc;
        padding-left: 20px;

        a {
            color: #7f92f3;
            font-weight: 600;
            transition: $transition;

            &:hover {
                color: lighten(#7f92f3, 5%);
            }
        }

        @include media-breakpoint-down(xs) {
            font-size: 15px;
        }
    }
}