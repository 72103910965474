.referral {
    &__ready-link {
        position: relative;
        display: inline-block;
        font-size: 25px;
        font-weight: 600;
        color: #9bacb6;
        margin-bottom: 25px;

        @include media-breakpoint-down(xs) {
            font-size: 22px;
        }

        &::after {
            content: "";
            position: absolute;
            display: block;
            width: 200px;
            border-bottom: 2px dashed rgba(155, 172, 182, 0.568);
            left: 0;
            bottom: 5px;
        }

        span {
            color: #78cd62;
        }

        &:hover {
            color: #9bacb6;
        }
    }

    &__link-wrapper {
        display: flex;
        background-color: #eaf0f4;
        border-radius: 3px;
        padding: 20px 80px 22px 25px;
        margin-bottom: 25px;

        @include media-breakpoint-down(md) {
            padding: 20px 40px 22px 25px;
        }

        @include media-breakpoint-down(sm) {
            padding: 20px 25px;
        }

        @include media-breakpoint-down(xs) {
            padding: 20px 15px;
        }
    }

    &__link-left {
        padding-right: 13px;

        @include media-breakpoint-down(xs) {
            display: none;
        }

        svg {
            width: 42px;
            max-width: initial;
            fill: #3B454B;

            @include media-breakpoint-down(md) {
                width: 32px;
            }
        }
    }

    &__link-right {
        p {
            margin: 0;
            line-height: 22px;
        }

        p:not(:first-child) {
            margin-top: 10px;
        }
    }

    &__link-title {
        color: #3b454b;
        font-size: 18px;
        font-weight: 700;
        margin-bottom: 10px;
    }

    &__link-shared {
        display: flex;
        margin-bottom: 10px;

        @include media-breakpoint-down(md) {
            flex-wrap: wrap;
        }

        .shared__link {
            display: flex;
            align-items: center;
            border-right: 2px solid #c6d5df;

            @include media-breakpoint-down(xs) {
                border: none;
            }

            span {
                font-size: 19px;
                font-weight: 700;
                color: #7f92f3;
                margin-right: 10px;

                @include media-breakpoint-down(xs) {
                    font-size: 17px;
                }
            }

            .shared__copy {
                margin-right: 12px;

                @include media-breakpoint-down(sm) {
                    margin-bottom: 0;
                }

                @include media-breakpoint-down(xs) {
                    margin-right: 0;
                }
            }
        }

        .shared__socials {
            display: flex;
            align-items: center;
            padding-left: 12px;

            a {
                width: 35px;
                height: 35px;
                border-radius: 50%;
                transition: 0.25s;

                &:not(:last-child) {
                    margin-right: 12px;
                }

                &:hover {
                    box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);

                    svg {
                        fill: #7f92f3;
                    }
                }

                svg {
                    transition: 0.25s;
                    fill: #9bacb6;
                }
            }
        }
    }
}

.loyalty__container {
    .filter-select__dropdown {
        min-width: 190px;
    }
}