html {
    box-sizing: border-box;
    // scroll-behavior: smooth;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Metropolis", "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
        "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    // -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    line-height: 1.5;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

*,
*::before,
*::after {
    box-sizing: inherit;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    line-height: 1.1;
}

ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

a,
a:hover {
    text-decoration: none;
}

input {
    height: 2rem;
}

input,
textarea {
    outline: none;
    width: 100%;
}

button {
    -webkit-appearance: none;
}

button,
button:focus {
    outline: none;
}

:focus {
    outline: none;
}

[type="radio"],
[type="checkbox"] {
    position: absolute;
    left: -9999px;
    visibility: hidden;
}

[type="checkbox"] + label {
    position: relative;
    cursor: pointer;

    &::before {
        content: "";
        position: absolute;
        display: block;
        top: 0;
        left: 0;
    }
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
    display: none;
}

input[type="text"]:focus:not([readonly]),
input[type="password"]:focus:not([readonly]),
input[type="email"]:focus:not([readonly]),
input[type="url"]:focus:not([readonly]),
input[type="time"]:focus:not([readonly]),
input[type="date"]:focus:not([readonly]),
input[type="datetime-local"]:focus:not([readonly]),
input[type="tel"]:focus:not([readonly]),
input[type="number"]:focus:not([readonly]),
input[type="search-md"]:focus:not([readonly]),
textarea.md-textarea:focus:not([readonly]) {
    border-bottom: none;
}

/* Change Autocomplete styles in Chrome*/
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    // border: 1px solid $primary !important;
    // -webkit-text-fill-color: #fff !important;
    -webkit-box-shadow: 0 0 0px 1000px #fff inset !important;
    transition: background-color 5000s ease-in-out 0s !important;
}

.text-muted {
    opacity: 0.4;
}

// !DISABLE ORDERFORM (UNTILL TRANSFER)
// !BLUR ORDERFORM
.orderform {
    pointer-events: none;
    filter: blur(2px);
}

.clickable {
    cursor: pointer;
}
