.modal-error {
    position: relative;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 5px 15px rgba(19, 22, 23, 0.15);
    width: 100vw;
    max-width: 600px;
    overflow: hidden;
    padding: 0 32px 32px;

    @include media-breakpoint-down(xs) {
        padding: 0 10px 10px;
    }

    .modal-close {
        position: absolute;
        top: 0;
        right: 0;
        border: none;
        box-shadow: none;
        background-color: transparent;
    }

    .modal-body {
        &__images {
            max-width: 126px;
            min-height: 136px;
            margin: 0 auto 1rem;

            img {
                width: 100%;
            }
        }

        &__heading {
            color: #313131;
            font-size: 17px;
            font-weight: 700;
            text-align: center;

            @include media-breakpoint-down(xs) {
                font-size: 16px;
            }
        }

        &__content {
            color: #5f5f5f;
            font-size: 14px;
            font-weight: 400;
            line-height: 24px;
            text-align: center;
        }

        &__buttons {
            display: flex;
            align-items: center;
            justify-content: center;

            @include media-breakpoint-down(sm) {
                flex-wrap: wrap;
            }

            .modal-button {

                &__help,
                &__method {
                    border-radius: 25px;
                    font-size: 15px;
                    font-weight: 400;
                    padding: .6rem .8rem;
                    margin: 1rem .5rem;
                    transition: $transition;

                    @include media-breakpoint-down(xs) {
                        padding: .6rem;
                        margin: .5rem;
                    }

                    @media screen and (max-width: 350px) {
                        margin: .5rem 0;
                    }

                    &:hover {
                        box-shadow: 0 4px 14px -4px rgba(0, 0, 0, .2), 0 2px 25px -5px rgba(0, 0, 0, .1), 0 1px 1px rgba(0, 0, 0, .05);
                    }
                }

                &__help {
                    border: none;
                    background-color: #00e8aa;
                    color: #fff;
                }

                &__method {
                    border: 2px solid #b4b4b4;
                    color: #5f5f5f;
                    background: transparent;
                }
            }
        }
    }
}