#root {
    display: flex;
    flex-direction: column;
    // min-height: 100vh;
}

#modal-root {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1100;
}

// .header {
//     background-color: #fff;
//     position: fixed;
//     top: 0;
//     left: 0;
//     width: 100%;
//     z-index: 100;
// }
// .header-logo {
//     width: 100px;
// }
// .nav-wrapper {
//     display: flex;
//     justify-content: space-between;
//     align-items: center;
//     min-height: 60px;
// }
// .main-nav {
//     display: flex;
//     justify-content: space-between;
//     align-items: center;
//     flex-grow: 1;
//     max-width: 480px;
// }
// .main {
//     display: flex;
//     margin: 80px 0 60px;
//     flex-grow: 1;
// }
.main-wrapper {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

section {
    flex-grow: 1;
}

.nav {
    height: 60px;
    display: flex;
    align-items: center;
}

.nav-link {
    display: inline-block;
    padding: 0.2rem 0.9rem;
    border-bottom: 1px solid #ccc;
}

.heading {
    font-size: 50px;
    font-weight: 700;
    margin-bottom: 20px;
    color: #3b454b;

    @include media-breakpoint-down(md) {
        font-size: 48px;
    }

    @include media-breakpoint-down(xs) {
        font-size: 30px;
    }
}

// .footer {
//     margin-top: auto;
//     background-color: rgba(204, 204, 204, 0.5);
//     .wrapper {
//         display: flex;
//         justify-content: center;
//         align-items: center;
//         height: 150px;
//     }
// }
.modal-wrapper {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    background-color: rgba(101, 147, 169, 0);
    transition: $transition;

    @include media-breakpoint-down(xs) {
        overflow: auto;
        align-items: start;

        &.centered {
            align-items: center;
        }
    }

    &.visible {
        background-color: rgba(101, 147, 169, 0.6);
    }
}

.modal-inner {
    transform: translateY(-1000px);
    transition: $transition;
    transition-delay: 0.1s;

    &.visible {
        transform: initial;
    }
}