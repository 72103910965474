// App variables
$primary: #4ca8c6 !default;
$success: #78cd62 !default;
$info: #7f93f3 !default;
$warning: #ffb900 !default;
$danger: #cc3032 !default;

$main-color: var(--main-color, #7ac8ed);
$grey-color: var(--grey-color, #f7f9fa);
$error-color: #d76c6d;
$transition: 0.25s;

@import "../../node_modules/bootstrap/scss/bootstrap-reboot.scss"; // Bootstrap Reboot collection
@import "../../node_modules/bootstrap/scss/mixins/_breakpoints.scss"; // Bootstrap Breakpoints mixin
@import "../../node_modules/bootstrap/scss/bootstrap-grid.scss"; // Optional Bootstrap Grid

@mixin customize-scrollbars($size: 6px, $thumb: $primary, $background: #fbfbfb, $visibility: hidden, $border-radius: 10px) {

    // Customize scrollbars
    /* Let's get this party started */
    &::-webkit-scrollbar {
        width: $size;
        height: $size;
    }

    /* Track */
    &::-webkit-scrollbar-track {
        background: #fff;
        box-shadow: inset 1px 1px 1px rgba(black, 0.07);
        border-radius: $border-radius;
        background: $background;
        visibility: $visibility;
    }

    &:hover::-webkit-scrollbar-track {
        visibility: visible;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        border-radius: $border-radius;
        background: $thumb;
        visibility: $visibility;
    }

    &:hover::-webkit-scrollbar-thumb {
        visibility: visible;
    }

    &::-webkit-scrollbar-thumb:window-inactive {}
}