.feedback-modal {
    background: #fff;
    padding: 1rem;
    border-radius: 10px;
    max-width: 360px;
    .modal {
        &__header {

        }
        &__body {
            .feedback-rating {
                svg {
                    &.empty {
                        fill: #dcdcdc;
                    }
                    &.full {
                        fill: #f3c539;
                    }
                }
            }
            .order-rating {
                margin: .4rem 0;
                color: rgba(158, 158, 158, 0.7);
                font-size: 13px;
            }
        }
        &__footer {
            display: flex;
            justify-content: flex-end;

            margin-top: .5rem;
            padding: .5rem;
            border-top: 1px solid rgb(228, 228, 228);
        }
        &__create {
            padding: .5rem 1.5rem;
            margin-right: 0.8rem;
            font-weight: bold;
            border-radius: 25px;
            color: #fff;
            border: none;
            background: #59d053;
        }
        &__close {
            // padding: .5rem 1.5rem;
            font-weight: bold;
            border-radius: 25px;
            color: #d76c6d;
            border: none;
            background: none;
        }
    }

    .feedback {
        &__textarea {
            padding: .8rem .5rem;
            margin-bottom: .5rem;
            border: none;
            max-height: 120px;
            min-height: 120px;
            box-shadow: inset 0 -10px 5px -10px rgba(0,0,0,.1)
        }
        &__label {
            color: #b3b3b3;
            font-size: 14px;
        }
    }
}