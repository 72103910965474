.filter {
    display: flex;
    font-size: 16px;

    @include media-breakpoint-down(xs) {
        font-size: 15px;
    }

    span {
        margin-right: 8px;
        color: #9bacb6;
    }
}

.filter-select {
    &__group {
        position: relative;
        width: 180px;
        color: #9bacb6;
        font-weight: 500;

        &.is-interact {
            .filter-select__output {
                color: $main-color;

                svg {
                    stroke: $main-color;
                }
            }
        }
    }

    &__output {
        display: inline-block;
        cursor: pointer;
        transition: $transition;

        &:hover {
            color: $main-color;

            svg {
                stroke: $main-color;
            }
        }

        svg {
            stroke: #9bacb6;
            margin-left: 10px;
            transition: 0.25s;
        }

        &.is-opened {
            svg {
                transform: rotate(180deg);
            }
        }

        &.disabled {
            opacity: 0.5;
            pointer-events: none;
        }
    }

    &__dropdown {
        position: absolute;
        display: none;
        left: 0;
        top: 22px;
        background-color: #fff;
        box-shadow: 0 10px 35px rgba(29, 42, 50, 0.2);
        border-radius: 3px;
        z-index: 10;
        min-width: 130px;

        &.is-opened {
            display: block;
        }
    }

    &__option {
        padding: 0.2rem 1rem;
        cursor: pointer;
        transition: 0.25s;

        &.is-highlighted {
            color: $main-color;
        }

        &:hover {
            color: $main-color;
        }
    }
}