.checkbox {
    &__group {
        margin-top: 26px;
    }

    &__input {
        visibility: visible;
        width: 0;
        height: 0;
        -webkit-appearance: none;
    }

    &__button {
        position: absolute;
        top: 0;
        left: 0;
        width: 46px;
        height: 46px;
        border-radius: 8px;
        border: 4px solid #e1e6e8;
        margin-top: 0;

        @include media-breakpoint-down(xs) {
            width: 40px;
            height: 40px;
        }

        &-arrow {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            fill: $main-color;
            opacity: 0;
            transition: .2s;
        }
    }

    &__label {
        display: inline-flex;
        align-items: center;
        color: #3b454b;
        font-size: 22px;
        font-weight: 500;
        height: 46px;
        padding-left: 74px;
        margin-bottom: 0;

        @include media-breakpoint-down(sm) {
            font-size: 19px;
        }
        @include media-breakpoint-down(xs) {
            font-size: 16px;
            padding-left: 52px;
        }
        &::before {
            display: none;
        }


        &:hover {
            &::before {
                border: 4px solid $main-color;
            }
        }

        span.calls-box {
            border-bottom: 2px dashed #9bacb6;
        }

        span.asterisk {
            color: #cc2f31;
        }
    }

    &__link {
        color: #85a7ff;
        @include media-breakpoint-down(xs) {
            &:first-child {
                // white-space: nowrap;
            }
        }
    }
}

.checkbox__input:focus+.checkbox__label .checkbox__button {
    border: 4px solid $main-color;
    box-shadow: 0 10px 35px $main-color;
}

.checkbox__input:checked+.checkbox__label .checkbox__button {
    border: 4px solid $main-color;
    &-arrow {
        opacity: 1;
    }
}