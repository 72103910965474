.urgency {
    &__group {
        display: flex;

        .select__group {
            max-width: 300px;
        }

        @include media-breakpoint-down(sm) {
            flex-direction: column;
            align-items: center;

            .select__group {
                max-width: 100%;
                width: 100%;
            }

            .select__box {
                width: 100%;
            }
        }
    }

    &__placeholder {
        display: inline-flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        border: 4px solid $main-color;
        border-radius: 8px;
        height: 60px;
        font-size: 30px;
        color: $main-color;
        font-weight: 500;
        cursor: pointer;
        transition: $transition;

        &:focus {
            box-shadow: 0 10px 35px $main-color;
        }

        @include media-breakpoint-down(sm) {
            font-size: 24px;
        }
    }

    &__decrement,
    &__increment {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 52px;
        width: 52px;
        border: none;
        background-color: transparent;
        cursor: pointer;

        svg {
            transition: $transition;
            fill: #3b454b;
        }

        &:hover {
            svg {
                fill: $main-color;
            }
        }
    }

    &__form-date {
        display: flex;
        align-items: center;
        align-self: center;
        margin: 12px 0 0 15px;
        transition: $transition;
        color: #9bacb6;

        svg {
            width: 16px;
            height: 16px;
            margin: 0 8px 3px 0;
            fill: #9bacb6;
        }

        @include media-breakpoint-down(sm) {
            margin: -15px 0 15px 0;
        }
    }
}